import analyticsManager from '@lmig/crab-rangoons';
import getEnv from '../../getEnv';

const PROPERTY_SUPPLEMENT_INTAKE_UI = 'PropertySupplementsPortal';

const loadAnalyticsManager = () => {
  const isProd = getEnv.ENV === 'production';
  const tealiumUrl = `https://tags.tiqcdn.com/utag/libertymutual/claims/${isProd ? 'prod' : 'dev'
  }/utag.js`;

  if (isProd) {
    analyticsManager.init(null, {
      isProd: true,
      galileoUrl: 'null',
      tealiumUrl,
      pageName: 'null'
    });
  } else {
    analyticsManager.init(null, {
      isProd: false,
      debugLogging: false,
      galileoUrl: 'null',
      tealiumUrl,
      pageName: 'null'
    });
  }
};

export const trackAnalyticsEvent = (event, isPageLoad) => {
  if (!analyticsManager) return;
  const payload = {
    pageName: PROPERTY_SUPPLEMENT_INTAKE_UI,
    ...event
  };
  if (isPageLoad) {
    analyticsManager.dispatchPageload(payload);
  } else {
    analyticsManager.dispatch(payload);
  }
};

export { loadAnalyticsManager };
